var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.highlighted_categories)?_c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex w-[calc(100%+2.5rem)] -ml-5 lg:ml-0 lg:w-full flex-nowrap gap-x-4 lg:gap-x-6 items-center overflow-x-scroll nicescroll lg:overflow-visible px-4 lg:px-0",class:_vm.isOverlay
                ? 'text-white justify-start mb-6 pb-4 border-b border-white border-opacity-20'
                : 'justify-center mb-6'},[(_vm.isOverlay)?_c('h3',{staticClass:"font-Inter-Bold lg:mr-4 uppercase text-lg lg:text-xl hidden md:flex"},[_vm._v("\n            Kiemelt kategóriák\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.cats.slice(0, 7)),function(category){return _c('button',{key:`category-${category.translations[0].id}`,staticClass:"whitespace-nowrap font-Inter-Bold bg-opacity-0 hover:bg-opacity-5 py-1 px-5 rounded-full transition border hover:border-opacity-100 border-transparent",class:{
                'text-white hover:text-white border-opacity-100 bg-opacity-100 hover:bg-opacity-100':
                    _vm.isActiveTab(category.translations[0].id) ||
                    _vm.selectedHighlightedCategory === parseInt(category.translations[0].id),
                'bg-blue-100 hover:text-blue-100 hover:border-blue-100': !_vm.isOverlay,
                'bg-white': _vm.isOverlay,
                'text-black hover:text-black':
                    (_vm.isOverlay && _vm.isActiveTab(category.translations[0].id)) ||
                    _vm.selectedHighlightedCategory === parseInt(category.translations[0].id),
            },on:{"click":function($event){return _vm.setHighlightedCategories(category.translations[0].id)}}},[_vm._v("\n            "+_vm._s(category.translations[0].name)+"\n        ")])})],2),_vm._v(" "),(_vm.products && _vm.products.length)?_c('div',[_c('div',{staticClass:"grid grid-cols-10 gap-3 lg:gap-4 mb-8 lg:mb-12"},_vm._l((_vm.products.slice(0, _vm.$device.isDesktop ? 5 : 6)),function(product){return _c('div',{key:`product-${product.id}`,staticClass:"col-span-5 lg:col-span-2 h-full",class:product?.has_banner ? 'lg:col-span-12' : ' col-span-5 lg:col-span-3'},[_c('div',{staticClass:"h-full",class:product?.has_banner && 'lg:grid lg:grid-cols-12 lg:gap-x-8'},[_c('div',{staticClass:"h-full",class:product?.has_banner && 'lg:col-span-3'},[_c('ProductCard',{staticClass:"h-full",attrs:{"title":product.name,"rating":5,"has-btn-text":false,"product":product,"slug":_vm.localePath({
                                    name: 'products-category-product',
                                    params: {
                                        category: product?.category_slug,
                                        product: product?.slug,
                                    },
                                }),"brutto-price":product.price,"is-favorite":product.isFavorite,"price":product.price,"discounted-price":product.discounted_price,"md":_vm.$device.isMobile,"image":product.category_name === 'Whispers of Wood' ? product.image : product.preview}})],1)])])}),0),_vm._v(" "),_c('div',{staticClass:"mb-8 lg:mb-24 flex justify-center"},[_c('more-button',{attrs:{"to":_vm.localePath({
                        name: 'products-category',
                        params: { category: _vm.activeCategory.translations[0].slug },
                    }),"label":`További ${_vm.activeCategory.translations[0].name} termékek`}})],1)]):_c('div',{staticClass:"text-center mb-12 lg:mb-40 flex flex-col items-center justify-center h-full mt-16"},[_c('i',{staticClass:"sad-emoji mb-3",attrs:{"data-not-lazy":""}}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',{staticClass:"lg:w-8/12 text-sm lg:text-base"},[_vm._v("\n            Lorem ipsum dolor sit amet, consectetur adipisicing elit Pariatur, perferendis?\n        ")])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"font-Inter-Bold text-xl lg:text-[1.5rem] mb-4 leading-[2rem]"},[_vm._v("\n            Sajnos a megadott keresési feltételekkel"),_c('br'),_vm._v("\n            nem találtunk termékeket\n        ")])
}]

export { render, staticRenderFns }