<template>
    <nuxt-link :to="localePath(slug)" class="flex flex-col group">
        <div
            class="rounded-md overflow-hidden mb-4 lg:min-h-[24.6875rem]"
            :class="
                wide
                    ? 'aspect-w-16 aspect-h-12 lg:aspect-w-2 lg:aspect-h-1'
                    : 'aspect-w-16 aspect-h-12 lg:aspect-w-1 lg:aspect-h-1'
            "
        >
            <img
                class="object-cover group-hover:scale-[1.04] transition transition--slow"
                :src="image"
                :alt="title"
                data-not-lazy
            />
        </div>
        <div class="flex flex-col">
            <h2 class="font-Inter-Bold mb-3" :class="md ? 'text-lg' : 'text-[1.25rem]'">{{ title }}</h2>
            <h3 class="text-base font-Inter-Regular mb-3 opacity-60">{{ subtitle }}</h3>
            <span class="flex items-center font-Inter-Bold"
                >Részletek
                <i class="ri-arrow-right-s-line leading-none text-xl ml-1 transition group-hover:translate-x-1"></i
            ></span>
        </div>
    </nuxt-link>
</template>

<script>
export default {
    name: 'EditorPreviewCard',
    props: {
        title: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: true,
        },
        wide: {
            type: Boolean,
            required: false,
            default: false,
        },
        md: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.transition--slow {
    transition-duration: 0.5s;
}
</style>
